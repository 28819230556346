const floor = (i: number) => Math.floor(i)

function digitize(...digits: number[]) {
  return digits
    .map(i => [Math.floor(i).toString().padStart(2, '0')])
    .join(':')
}

// TODO Write tests
export function formatSeconds(value: number) {
  const hours = floor(value / 3600)
  const baseMinutes = floor(value / 60)
  const baseSeconds = baseMinutes * 60

  const minutes = baseMinutes - hours * 60
  const seconds = value - baseSeconds

  return hours
    ? digitize(hours, minutes, seconds)
    : digitize(minutes, seconds)
}
