<script lang="ts" setup>
import { Plans } from 'core'

const features = Array.from(Plans.PRO.features).slice(2)
</script>

<template>
  <section v-once class="max-w-400px">
    <h5 class="text-neutral-700 text-sm font-medium">
      Upgrade to {{ Plans.PRO.name }}
    </h5>

    <h2 class="mb-8 text-2xl text-neutral-700 sm:text-4xl font-semibold">
      <span class="text-primary-500">Unlimited</span> messages & recording time
    </h2>

    <!-- Features -->
    <h5 class="mb-3 text-neutral-400 text-sm font-medium">
      Features you'll unlock
    </h5>

    <OverlayClampContent class="p-3 rounded-10px bg-white border-1 border-neutral-100 data-[state=open]:border-1 data-[state=open]:border-solid data-[state=open]:border-neutral-200 data-[state=open]:elevation-bottom-4" clamp-class="lt-md:line-clamp-2 text-ellipsis">
      <p v-for="feature, i in features" :key="i" class="text-sm text-neutral-500 font-medium not-last:mb-3">
        <i-mdi-check class="mr-2 align-middle text-primary-500 text-base" />
        {{ feature }}
      </p>
    </OverlayClampContent>
  </section>
</template>
