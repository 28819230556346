import type { AudioBlob } from 'vocal-recorder'
import { getTheme } from 'core'
import { SignatureRenderer } from '.'

/** Renders user signature from `AudioBlob` */
export async function renderUserSignature(audio: AudioBlob) {
  const settings = useSettings()
  const signature = new SignatureRenderer({
    name: settings.user.name,
    message: settings.signature.message,
    avatarURL: settings.user.avatarUrl,
    avatarSize: 28,
    audio: {
      peaks: Array.from(audio.peaks),
      duration: audio.duration.seconds
    },
    theme: getTheme(settings.themes[settings.signature.theme])
  })

  return signature.render()
}
