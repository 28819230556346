import { fetchBase64 } from 'core'

/** Silently resolves url as base64 and logs errors to ErrorService */
export function safeFetchBase64<T extends string>(url: string, props: {
  title: string
  fallback?: T | string
}) {
  return fetchBase64(url).catch((error) => {
    ErrorService.log(error, { title: props.title })
    return props.fallback ?? ''
  })
}

export function serializeAsXML(source: string) {
  const node = document.createElement('div')
  node.innerHTML = source
  return new XMLSerializer().serializeToString(node)
}

/** Calculates the size of a unmounted node or html string */
export function getDOMSize(source: string | Node) {
  const div = document.createElement('div')
  const styles: Partial<CSSStyleDeclaration> = {
    position: 'absolute',
    visibility: 'hidden',
    display: 'block',
    verticalAlign: 'middle',
    margin: '0',
    padding: '0'
  }

  Object.assign(div.style, styles)

  if (typeof source === 'string')
    div.innerHTML = source

  else if (source instanceof Node)
    div.appendChild(source)

  else
    throw new Error('a valid source was not given')

  document.body.appendChild(div)
  const size = {
    width: div.offsetWidth,
    height: div.offsetHeight
  }
  document.body.removeChild(div)

  return size
}

/** Returns blob url from url or blob. */
export function useBlobUrl(source: string | Blob) {
  if (source instanceof Blob) {
    const url = URL.createObjectURL(source)

    tryOnBeforeUnmount(() => URL.revokeObjectURL(url))
    return url
  }

  return source
}
