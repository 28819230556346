<script lang="ts" setup>
import { sendSupportMail } from 'core'
import { State, useEvents, useState } from '../logic'

const state = useState()
const events = useEvents()
</script>

<template>
  <div class="grid gap-4 max-w-lg">
    <template v-if="state.current === State.success">
      <h2 class="title">
        Payment successful
      </h2>
      <p class="subtitle">
        You have subscribed to the <b>PRO</b> plan and unlocked all the features.
      </p>

      <CTAButton class="w-full max-w-300px mx-auto" @resolve-click="events.finish.trigger">
        Continue
      </CTAButton>
    </template>

    <template v-else-if="state.current === State.error">
      <h2 class="title">
        We could not process your payment
      </h2>
      <p class="subtitle">
        Make sure your details are correct and then you can try again.
        If this issue persists, please contact us.
      </p>

      <div class="grid gap-4 w-full max-w-300px mx-auto">
        <CTAButton @click="state.$reset()">
          Retry
        </CTAButton>
        <CTAButton variant="secondary" @click="sendSupportMail()">
          Contact us
        </CTAButton>
      </div>
    </template>
  </div>
</template>

<style scoped lang="sass">
.title
  @apply text-primary-500 text-2xl sm:text-4xl font-semibold

.subtitle
  @apply mb-10 text-neutral-500
</style>
