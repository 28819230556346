<script lang="ts" setup>
import { useAPIHandle } from 'core'
import { useState } from '../../logic'

defineProps<{
  cause?: string
  url: string
}>()

const [state, verify] = useAPIHandle(async () => {
  if (document.visibilityState === 'hidden')
    return
  await useState().verify()
})

useEventListener(window, 'visibilitychange', verify)
</script>

<template>
  <div class="p-4 gap-3 flex-col-center text-center h-full border-1 border-dashed border-neutral-200 border-rounded-10px">
    <i-solar-shield-warning-bold class="text-4xl text-error-500 mt-auto" />
    <h3 class="text-lg text-neutral-800 font-medium">
      {{ cause || 'Authentication Required' }}
    </h3>
    <p class="mb-auto text-sm text-neutral-700">
      You have to verify your transaction with Stripe to continue this process.
    </p>

    <CTAButton
      v-if="state.loading"
      :disabled="true"
      :loading="state.loading"
      loading-label="Please wait..."
      variant="secondary"
      class="w-full"
      inert
    />

    <a v-else :href="url" target="_blank" class="w-full">
      <CTAButton class="w-full ">Verify</CTAButton>
    </a>
  </div>
</template>
