import { formatSeconds } from 'core'
import { useState } from '../../logic'

export function useTimer({
  /** Start the timer immediately */
  autostart = true,

  /** Shows a countdown */
  countdown = 0,

  /** Triggered when countdown finishes */
  callback = () => {}
}) {
  const ui = useState().ui.header
  const elapsed = ref(0)

  const formatted = computed(() => {
    const value = elapsed.value

    return countdown ? (countdown - value) : formatSeconds(value)
  })

  const timer = useIntervalFn(
    () => {
      elapsed.value++

      // End for countdown
      if (countdown && elapsed.value >= countdown) {
        pause()
        callback()
      }
    },
    1000,
    { immediate: autostart }
  )

  function pause() {
    timer.pause()
    ui.timerPaused = true
  }

  function resume() {
    timer.resume()
    ui.timerPaused = false
  }

  // Countdown can be skipped by enter press
  if (countdown)
    ui.timer = countdown

  // Cleanup
  tryOnBeforeUnmount(() => {
    ui.timer = 0
    ui.timerPaused = false
  })

  return { elapsed, formatted, pause, resume }
}
