import SFX_START from '@assets/audio/recording-start.mp3'

import SFX_STOP from '@assets/audio/recording-stop.mp3'
import { promiseDebounce } from 'core'
import { type AudioBlob, AudioRecorder, type RecorderEvent } from 'vocal-recorder'
import { useTimer } from '../components/common/Layout'

function play(src: string) {
  return new Audio(src).play().then(() => true, () => false)
}

/** Local settings used by {@link ReactiveAudioRecorder} */
export const useRecorderSettings = defineStore('vocal-recorder-settings', {
  persist: true,

  state: () => ({
    deviceId: '',
    beepSounds: true

  } satisfies MediaTrackConstraints | object)
})

/** Shared instance of `useRecorderState` */
export const useRecorder = createGlobalState(() => {
  const instance = new AudioRecorder()
  const settings = useRecorderSettings()
  const state = shallowReactive(instance.state)

  const events = {
    error: createEventHook<Error>(),
    result: createEventHook<AudioBlob>(),
    change: createEventHook<{
      key: RecorderEvent
      state: typeof instance.state
      prevState: typeof instance.state
    }>()
  }

  function createHook<T>(fn: () => Promise<T>) {
    return promiseDebounce(() =>
      fn().catch((error) => {
        events.error.trigger(error)
      })
    )
  }

  // Functions
  const init = createHook(() => instance.init(settings))

  /** Starts recording */
  const start = createHook(async () => {
    await init()
    await instance.start()
    track('Recorder: started')
  })

  /** Stops recording and returns result */
  const stop = createHook(() => instance.stop())

  // TODO: deprecate `finish` hook and only use `stop`
  /** Stops recording and emits {@link events.result}  */
  const finish = createHook(async () => {
    const result = await stop()

    if (result)
      events.result.trigger(result)

    track('Recorder: finished')
    return result
  })

  /** Toggles resume/pause state. Ignored when inactive */
  function toggle() {
    const { state } = instance

    if (state.inactive)
      return console.warn('Cannot toggle recorder from inactive state')

    return state.recording ? instance.pause() : instance.resume()
  }

  // Listen for recorder changes
  instance.events.on('*', (key) => {
    const prevState = toRaw(state)
    const newState = instance.state

    // Play state change sfx
    if (settings.beepSounds)
      play(newState.recording ? SFX_START : SFX_STOP)

    // Update states
    events.change.trigger({ key, state: newState, prevState })
    Object.assign(state, newState)
  })

  return {
    state,
    events,
    settings,
    instance,

    // Functions
    init,
    start,
    stop,
    finish,
    toggle,

    // Hooks
    onError: events.error.on,
    onResult: events.result.on,
    onChange: events.change.on
  }
})

interface RecorderFlowConfig {
  /** Start recording on mount */
  startOnmount?: boolean

  /** Limit recording duration. `0` means no limit */
  limitLength?: number

  /** Fired when `reset()` called after stopping recorder */
  onReset?: () => void

  /** Fired when result {@link AudioBlob} is available */
  onResult?: (result: AudioBlob) => void

  onError?: (error: Error) => void
}

/**
 * Composable for building recording flows.
 * Instance is bound to the component and not shared.
 */
export function useRecorderFlow(config: RecorderFlowConfig = {}) {
  const recorder = useRecorder()

  const timer = useTimer({
    countdown: config.limitLength,
    autostart: false,
    callback: recorder.finish
  })

  /** Resets recording and moves to init page */
  const reset = () => recorder.stop().finally(config.onReset)

  // Toggle timer
  recorder.onChange(({ state }) => state.recording ? timer.resume() : timer.pause())

  // Events
  if (config.onError)
    recorder.onError(config.onError)
  if (config.onResult)
    recorder.onResult(config.onResult)

  if (config.startOnmount)
    onBeforeMount(recorder.start)

  onUnmounted(() => {
    if (!recorder.state.inactive)
      recorder.stop()
  })

  return { ...recorder, reset, timer }
}
