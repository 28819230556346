import type { PartialDeep, Promisable } from 'type-fest'
import { type PiniaStore, Platform, probeError, useSession } from 'core'
import idb from 'localforage'
import { type AudioBlob, prefetchEncoder } from 'vocal-recorder'

import { usePaywall } from '../../paywall'
import { renderUserSignature } from '../../signature'
import { Attachment } from './attachment'
import { AudioResultIDBKey, Errors, log, type RecorderError, usePersistentAudio } from './utils'

/** Active screen in the recorder */
export enum Screen {
  /** Init phase. Acts as reset as well */
  init = 'init',
  countdown = 'countdown',
  recording = 'recording',
  preview = 'preview',
  success = 'success'
}

/** Config of recorder */
export class Config {
  constructor(props: Partial<Config> = {}) {
    Object.assign(this, props)
  }

  /** Audio file to upload. Useful for custom uploads */
  audio?: AudioBlob

  /** UID of recording entry */
  id = ''
  domain: string = import.meta.env.VITE_APP_WEBAPP_ORIGIN

  /** Disables login */
  authless = false

  /** Platform of recorder */
  platform = Platform.UNDEFINED

  /** UI config for the recorder */
  ui?: PartialDeep<Store['ui']>

  /** User message context */
  context?: {
    subject: string
    recipients: string[]
  }

  /** Triggered when close button is clicked */
  onClose() { }

  /** Triggered at the end of {@link useState state init} phase. Reset without `flush` re-triggers this as well */
  onInit(): Promisable<void> { }

  /** Triggered before uploading. Async function is awaited before upload starts */
  onBeforeUpload(_attachment: Attachment): Promisable<void> { }

  /**
   * Triggered after uploading.
   * - Returning `true` will call `state.close()` which cleans up and calls the `onClose()` hook
   */
  onUploaded(_attachment: Attachment): Promisable<boolean | void> { }
}

export const useState = defineStore('recorder', {
  state: () => ({
    screen: Screen.init,

    /** Recorder error. Setting a value shows the error screen */
    error: undefined as RecorderError | undefined,

    /** Recording result */
    result: usePersistentAudio(AudioResultIDBKey),

    // Non-reactive value
    static: markRaw({
      config: new Config()
    }),

    /** UI settings of recorder */
    ui: {
      /** Allow recording to be downloaded */
      allowDownload: true,

      header: {
        /** Toggles header visiblity */
        enabled: true,

        /** Toggles header close button visiblity */
        closeButton: true,

        /** Timer for progress bar */
        timer: 0,

        /** Pauses timer */
        timerPaused: false
      }
    }
  }),

  getters: {
    session: () => useSession(),
    isPRO: () => useSession().user.subscribed,
    isAuthless: state => state.static.config.authless,

    showLoginScreen(state) {
      const session = useSession()
      return !state.static.config.authless && !session.isAuthorized
    }
  },

  actions: {
    /** Sets audio result and moves to preview screen */
    setResult(result?: AudioBlob) {
      if (result && result instanceof Blob && result.size !== 0) {
        this.result = result
        this.screen = Screen.preview
      }

      else {
        this.error = Errors.NullRecordingResult
      }
    },

    /**
     * Can be used to re-record
     * @param flush - Used to fully dispose the recorder. Will not re-init the recorder
     */
    async reset(flush = false) {
      this.result = undefined
      this.screen = Screen.init

      return flush ? this.$reset() : this.init()
    },

    /** Cleans up and calls `onClose()` hook */
    close() {
      const { onClose } = this.static.config

      this.reset(true)
      return onClose()
    },

    /**
     * - Called by the init page
     * - Assigns recording UID if given, otherwise gets one from server
     */
    init(props: Partial<Config> = {}) {
      return tryFn(Errors.InitFailed, async () => {
        // Set init screen
        this.screen = Screen.init

        // Normalize
        const config = Object.assign(this.static.config, props)
        Object.assign(this.ui, config.ui)

        // Wait for authorization
        if (!config.authless) {
          await this.session.sync()

          const { user } = this.session
          const { messages } = user

          // Update user domain
          config.domain = user.domain

          // Show paywall
          if (messages.showPaywall)
            await this.showPaywall()

          log('state: synced', user)
        }

        // Validate uid if exists
        if (config.id) {
          const uploaded = await api.audio
            .getPublic(config.id)
            .then(e => e.audio.uploaded, () => false)

          // ! Show success screen on Gmail Addon
          if (uploaded && config.platform === Platform.GMAIL_ADDON) {
            this.screen = Screen.success
            return
          }

          // ! Clear id if it's already uploaded
          if (uploaded)
            config.id = ''

          log('state: verified provided id', { uploaded })
        }

        // Fetch new uid
        if (!config.id) {
          log('state(init): fetching new uid')

          const data = await Errors.UIDFetchFailed.run(() =>
            api.audio.requestUpload(config.platform, config.authless)
          )

          config.id = data.uuid
          config.domain ||= data.custom_domain
        }

        await Errors.RecorderLoadFailed.run(() => prefetchEncoder())
        await config.onInit()

        log('state(init): done', config)
        track('Recorder: loaded', config)

        // Move to preview screen if an audio is provided
        if (props.audio)
          return this.setResult(props.audio)

        // Move to preview screen if a previous audio is available
        if (this.result) {
          log('Previous audio was found. Moving to preview screen')
          return this.setResult(this.result)
        }

        this.screen = Screen.countdown
      })
    },

    upload() {
      return tryFn(Errors.UploadFailed, async () => {
        const { settings } = this.session
        const { config } = this.static
        const { result: audio } = this

        if (!audio)
          throw new Error('No audio result available')

        const signature = await Errors.SignatureRenderFailed.run(() => renderUserSignature(audio))

        // Create result entry for passing
        const attachment = Attachment.create({ config, settings, audio: audio.blob, signature })
        const context = Object.assign({}, config.context, { peaks: audio.peaks })

        const trackingData = {
          $uid: config.id,
          $size: audio.size,
          $type: audio.type,
          $duration: audio.duration.toString()
        }

        track('Uploading recording', trackingData)

        // Send result back to parent
        await config.onBeforeUpload(attachment)

        // Upload files to server
        const success = await api.audio.upload(config.id!, audio, signature.blob, context)

        if (!success) {
          track('Upload failed', trackingData)
          throw new Errors.UploadFailed()
        }

        // Remove audio result from idb
        await idb.removeItem(AudioResultIDBKey)

        track('Uploaded recording', trackingData)
        const shouldClose = await config.onUploaded(attachment)

        this.screen = Screen.success
        this.result = undefined

        if (shouldClose)
          await this.close()
      })
    },

    /** Downloads the result if exists */
    download() {
      const { result } = this

      if (!result)
        return console.warn('No result to download')

      const name = result.getFilename(`Vocal Recording - ${new Date().toLocaleString()}`)
      downloadFile(result, name)
    },

    showPaywall() {
      const external = [Platform.WEB_EXTENSION, Platform.FRONT_APP].includes(this.static.config.platform)

      if (external)
        return this.session.openPaymentFlow()

      const paywall = usePaywall()
      const { messages } = this.session.user

      paywall.setProps({
        canSkip: messages.count < messages.max,
        external
      })

      return paywall.show()
    }
  }
})

/**
 * Runs functions and shows error screen in the recorder ui when errors happen
 * @param BaseError Fails with this error if no {@link RecorderError} is raised
 */
export function tryFn<R>(BaseError: RecorderError, fn: () => R) {
  const state = useState()

  return probeError(fn, (error?: any) => {
    if (!Errors.includes(error))
      error = new BaseError(error)

    log.error('Error occurred\n\n', error)
    ErrorService.log(error)
    state.error = error
  })
}

export type Store = PiniaStore<typeof useState>

export const useStateRefs = () => storeToRefs(useState())
