<script lang="ts" setup>
import type { Promisable } from 'type-fest'
import { RequireLogin } from '../../login'
import { State, usePaywallStats, useState } from '../logic'

import Checkout from './Payment.Checkout.vue'
import Header from './Payment.Header.vue'
import Result from './Payment.Result.vue'

export interface PaywallProps {
  /** Disable header title */
  noHeader?: boolean

  /** Whether flow happens in external window */
  external?: boolean

  onClose?: () => Promisable<void>
}

defineProps<PaywallProps>()

const state = useState()
const session = useSession()
const stats = usePaywallStats()
</script>

<template>
  <section class="p-x-4 p-t-3 p-b-8 sm:p-6 sm:pb-8 lg:p-13 flex-col-center bg-white rounded-xl">
    <header v-if="!noHeader" class="mb-6 w-full flex-center">
      <VocalLogo logo-only width="32" class="mr-auto" />
      <IconButton v-if="onClose && !stats.maxed" icon="i-mdi-close" size="base" @resolve-click="onClose" />
    </header>

    <RequireLogin wrapper-class="m-auto">
      <!-- Progress -->
      <div v-if="!session.user.subscribed" class="mb-6 w-full">
        <LinearProgress
          :value="stats.progress" class="mb-2 !h-8px !md:h-20px data-[neutral=true]:$fill-color-#8BA6C7"
          :data-neutral="stats.maxed"
        />

        <span class="text-neutral-500 text-xs">
          <b>{{ stats.current }}/{{ stats.max }}</b> {{ stats.maxed ? 'message limit reached!' : 'Recordings this month'
          }}
        </span>
      </div>

      <!-- Main screen -->
      <div
        v-if="state.current === State.checkout" class="gap-6 flex flex-col items-center"
        :class="{ 'md:flex-row': !external }"
      >
        <Header class="scroll-snap-align-end" />

        <CTAButton v-if="external" class="w-full" @resolve-click="session.openPaymentFlow">
          Upgrade now
        </CTAButton>

        <Checkout v-else class="scroll-snap-align-start" />
      </div>

      <!-- Result screen  -->
      <Result v-else />
    </RequireLogin>
  </section>
</template>
