<script lang="ts" setup>
import { Plans } from 'core'
import { useState } from '../logic'
import StripeInput from './stripe/StripeInput.vue'

const state = useState()
</script>

<template>
  <section class="w-full max-w-320px grid p-x-4 p-y-6 gap-8 rounded-2xl elevation-regular-3 flex-shrink-0 bg-white">
    <header>
      <h4 class="mb-10px text-heading-4">
        {{ Plans.PRO.name }}
      </h4>
      <p class="text-paragraph-4 text-neutral-400">
        10-Day Full Refund Guarantee
      </p>
    </header>

    <StripeInput>
      <!-- Packages -->
      <div class="mb-auto gap-4 grid grid-cols-2">
        <button
          v-for="entry in Plans.PRO.packages"
          :key="entry.key"
          class="h-100px rounded-10px bg-neutral-50 border-1 border-solid border-transparent outline-primary-400 text-neutral-500 [&.active]:text-primary-500 [&.active]:border-primary-500"
          :class="{ active: state.package.key === entry.key }"
          :title="`Select ${entry.period} plan`"
          @click="state.setPackage(entry)"
        >
          <span :class="{ invisible: !entry.savings }" class="text-11px">Save {{ entry.savings }}%</span>

          <h5 class="text-display-2">
            <sup class="-ml-16px mr-6px align-middle text-base">$</sup>{{ entry.price }}
          </h5>

          <span class="text-xs text-neutral-500">{{ entry.period }}</span>
        </button>
      </div>
    </StripeInput>
  </section>
</template>
