<script lang="tsx" setup>
import type { Props as LoginProps } from './Login.vue'
import { useSession } from 'core'

interface Props extends LoginProps {
  enabled?: boolean
  loading?: boolean
}

const { enabled = true, ...props } = defineProps<Props>()

const session = useSession()
const Login = defineAsyncComponent(() => import('./Login.vue'))
</script>

<template>
  <div v-if="loading || !session.isReady || (http.isAuthorized && !session.isSynced)" class="flex-center h-full w-full">
    <Spinner text-primary-500 />
  </div>

  <Login v-else-if="enabled && !http.isAuthorized" v-bind="props" />

  <slot v-else />
</template>
