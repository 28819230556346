import { getURLSearchParams } from 'core'

export class GoogleAuthProvider {
  static login() {
    const env = import.meta.env
    const url = new URL('https://accounts.google.com/signin/oauth')

    url.searchParams.set('client_id', env.VITE_APP_GAPI_CLIENT_ID)
    url.searchParams.set('redirect_uri', `${env.VITE_APP_WEBAPP_ORIGIN}/auth`)
    url.searchParams.set('response_type', 'id_token')
    url.searchParams.set('scope', 'profile email')
    url.searchParams.set('nonce', 'NONCE')
    url.searchParams.set('prompt', 'select_account')

    location.href = url.href
  }

  static parseToken() {
    return getURLSearchParams<{ id_token?: string }>().id_token
  }
}
