import type { SignatureResult } from '../../signature'
import type { Config } from './state'
import { fetchBase64, parseURL, type Prettify, type SettingsStore } from 'core'

/** Attachment produced by RecorderUI */
export interface Attachment {
  /** Recording UID */
  id: string
  domain?: string
  signature?: SignatureResult
  audio?: File
}

export namespace Attachment {
  /** Creates {@link Attachment}  */
  export function create(params: {
    config: Config
    settings: Prettify<Pick<SettingsStore, 'signature' | 'attachment'>>
    audio: Blob
    signature: SignatureResult
  }): Attachment {
    const { audio, signature, settings } = params
    const { id, domain = import.meta.env.VITE_APP_WEBAPP_ORIGIN } = params.config

    if (!id)
      throw new Error('config.id is null')

    const audioFile = new File([audio], `${settings.attachment.name || 'recording'}.mp3`, {
      type: audio.type,
      lastModified: Date.now()
    })

    return {
      id,
      domain,
      audio: settings.attachment.enabled ? audioFile : undefined,
      signature: settings.signature.enabled ? signature : undefined
    }
  }

  interface HTMLTemplateConfig {
    /**
     * When true, a base64 fallback image is added in the html content.
     * Useful when attachment is added before uploading source files
     * @default false
     */
    fallbackImage?: boolean

    /**
     * Email address of recipient.
     * This is appended to the listen page link as `?email`
     */
    email?: string
  }

  export function getDetails(props: Attachment) {
    const { id, domain = import.meta.env.VITE_APP_WEBAPP_ORIGIN } = props
    const assetUrl = (file: string) => `https://storage.googleapis.com/vocal_messages/${file}`

    return {
      ...props,
      domain,
      pageUrl: parseURL(domain, `/listen/${id}`),
      imageUrl: assetUrl(`${id}.png`),
      audioUrl: assetUrl(`${id}.mp3`)
    }
  }

  /** Creates HTML content for attaching in email client body */
  export async function getHTMLTemplate(props: Attachment, config: HTMLTemplateConfig = {}) {
    const { signature, imageUrl, pageUrl } = getDetails(props)
    const tooltip = 'Click to listen to this message'

    if (config.email)
      pageUrl.searchParams.set('email', config.email)

    async function createImage(image: SignatureResult) {
      const sizeAttrs = `width="${image.width}" height="${image.height}"`

      if (!config.fallbackImage)
        return `<img ${sizeAttrs} src="${imageUrl}" alt="${tooltip}"></img>`

      const imageBase64 = await fetchBase64(image.blob)

      return `
      <picture alt="${tooltip}">
        <source ${sizeAttrs} srcset="${imageBase64}">
        <img ${sizeAttrs} loading="lazy" src="${imageUrl}"></img>
      </picture>`
    }

    const body = signature ? await createImage(signature) : pageUrl.href

    return `
    <br>
    <div style="margin: 10px 0px; user-select: none;" contenteditable="false">
      <a href="${pageUrl.href}" draggable="false" title="${tooltip}" target="_blank">
        ${body}
      </a>
    </div>
    <br>
    `
  }

  /** Attaches {@link Attachment} to any compose view  */
  export async function attach(
    props: Attachment,
    options: HTMLTemplateConfig & {
      /** Attaches audio file. Only fires if audio is available */
      attachAudio: (file: File) => void

      /** Attaches audio link. Signature is attached if available */
      attachContent: (content: string) => void
    }
  ) {
    Logger.debug('📋 Attaching to compose client', props)

    return Promise.all([
      // Attach audio file
      props.audio && options.attachAudio(props.audio),

      // Attach signature image
      getHTMLTemplate(props, options).then(options.attachContent)
    ])
  }
}
