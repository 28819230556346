export const useEvents = createSharedComposable(() => {
  return {
    /** When user has opened/closed the paywall popup. Only available for `usePaywall` context */
    popupChange: createEventHook<boolean>(),

    /** When user has subscribed */
    success: createEventHook<void>(),

    /** When user has finished the paywall flow after subscribing */
    finish: createEventHook<void>()
  }
})
