import type { Stripe, StripeCardElement, StripeCardElementOptions, StripeElementsOptionsClientSecret } from '@stripe/stripe-js'
import type { MaybeRef } from 'vue'
import { DeferredPromise } from 'core'

enum StripeError {
  LOAD_ERROR = 'Stripe failed to load',
  NO_TARGET_WRAPPER = 'Could not find target element to mount stripe cards'
}

export class StripeElement {
  protected instance = new DeferredPromise<Stripe>()
  protected cardElement!: StripeCardElement

  constructor() {
    this.init()
  }

  private async init() {
    // Load stripe instance
    const lib = await import('@stripe/stripe-js')

    await lib.loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY).then((module) => {
      if (!module)
        throw new Error(StripeError.LOAD_ERROR)

      this.instance.resolve(module)
    })
  }

  async mountCardForm(wrapperRef: MaybeRef<HTMLElement | undefined>, elementsConfig?: StripeElementsOptionsClientSecret, cardConfig?: StripeCardElementOptions) {
    const stripe = await this.instance
    const elements = stripe.elements(elementsConfig)
    const card = elements.create('card', cardConfig)
    const wrapper = toValue(wrapperRef)

    if (!wrapper)
      throw new Error(StripeError.NO_TARGET_WRAPPER)

    card.mount(wrapper)
    return this.cardElement = card
  }

  async getResult() {
    const stripe = await this.instance
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement
    })

    return result
  }
}
