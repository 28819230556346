<script lang="ts" setup>
defineOptions({
  inheritAttrs: false
})

defineProps<{
  clampClass?: string
}>()

const contentRef = ref<HTMLElement>()

const isOpen = ref(false)
const isClamped = ref(false)

const onResize = debounce(200, () => {
  const node = contentRef.value

  if (node)
    isClamped.value = node.scrollHeight > node.clientHeight
})

useEventListener('resize', onResize)
onMounted(onResize)
</script>

<template>
  <div class="relative">
    <div ref="contentRef" v-bind="$attrs" :class="clampClass">
      <IconButton v-if="isClamped" class="float-right" icon="i-mdi-chevron-down" @click="isOpen = true" />
      <slot />
    </div>

    <Transition v-if="isClamped" name="fade">
      <div v-if="isOpen" v-bind="$attrs" class="z-100 absolute top-0 inset-x-0 !line-clamp-none" data-state="open">
        <IconButton class="float-right" icon="i-mdi-close" @click="isOpen = false" />

        <slot />
      </div>
    </Transition>
  </div>
</template>
