import LOGO_URL from '@assets/images/branding/vocal-identity-logo.svg?url'

import iconPlay from '~icons/solar/play-bold?raw'
import { convertImage, CSSTheme, formatSeconds, promiseDebounce, type Theme } from 'core'

import { getPeaksSVG } from '../../common/audio-player/utils'
import styles from '../Card.module.sass'
import { getFont } from './fonts'
import { getDOMSize, safeFetchBase64, serializeAsXML } from './utils'

export interface SignatureConfig {
  /** Can be a HTTP url or base64 data */
  avatarURL?: string
  avatarSize: number

  /** Name of user */
  name: string
  message: string

  audio: {
    /** Audio peaks data */
    peaks: number[]
    duration: number
  }

  /** Base theme palette */
  theme: Theme
}

export interface SignatureResult {
  blob: Blob
  width: number
  height: number
  scale: number
}

export const themeColors: Theme.CSSColorRecord = {
  'bg': ['background'],
  'text': ['neutral'],
  'border': ['neutral', 0.15],

  'player-cta-bg': ['primary'],
  'player-cta-outline': ['primary'],
  'player-cta-icon': ['background'],

  'player-bar-color': ['neutral', 0.4],
  'player-bar-fill-color': ['primary']
}

async function getStyles(cssTheme: CSSTheme) {
  const fontName = cssTheme.props.font
  const [fontStyles, cssStyles] = await Promise.all(
    [
      getFont(fontName),
      import('../Card.module.sass?inline')
    ]
  )

  return `
    <style>
      ${fontStyles}

      :root {
        font-family: ${fontName}, Inter, sans-serif;
      }

      /** Override colors */
      .${styles.wrapper} {
        ${cssTheme.getVariablesCSS(themeColors)}
      }

      ${cssStyles.default}
    </style>
  `
}

async function buildAvatar({ avatarSize: size, avatarURL: url, name }: SignatureConfig) {
  // Get remote avatar
  if (url) {
    const data = await safeFetchBase64(url, {
      title: `Failed to fetch avatarURL from ${url}`
    })

    return `<img class="${styles.header_avatar}" src="${data}" width="${size}" height="${size}">`
  }

  // Return initials
  const initials = String(name).slice(0, 1)
  return `<div class="${styles.header_avatar}">${initials}</div>`
}

async function getTemplate(config: SignatureConfig) {
  // Normalize
  const name = config.name ||= 'Someone'

  /** Create css theme from given theme palette */
  const cssTheme = new CSSTheme(config.theme)

  const [css, avatar, brandLogo] = await Promise.all([
    getStyles(cssTheme),
    buildAvatar(config),
    safeFetchBase64(LOGO_URL, {
      title: `Failed to fetch brand logo from ${LOGO_URL}`
    })
  ])

  const peaks = getPeaksSVG({
    peaks: config.audio.peaks,
    rect: { width: 226, height: 40 },
    barColor: cssTheme.getColor('neutral', 0.4)
  })

  const html = `
    <div class="${styles.wrapper}">
      <div class="${styles.header}">
        ${avatar}
        <span class="${styles.header_name}">${name}</span>
        <div class="${styles.header_brand_logo}" style="--logo-url: url(${brandLogo});"></div>
      </div>

      <div class="${styles.message}">${config.message}</div>

      <div class="${styles.player}">
        <div class="${styles.player_cta}">${iconPlay}</div>
        ${peaks.code}
        <span class="${styles.player_duration}">${formatSeconds(config.audio.duration)}</span>
      </div>
    </div>
  `

  const rect = getDOMSize(html)

  const template = `
    <svg width="${rect.width}" height="${rect.height}" xmlns="http://www.w3.org/2000/svg">
      <foreignObject width="100%" height="100%">
        ${css}
        ${serializeAsXML(html)}
      </foreignObject>
    </svg>
  `

  return { template, rect }
}

export class SignatureRenderer {
  constructor(readonly config: SignatureConfig) { }

  #getTemplate = promiseDebounce(() => getTemplate(this.config))

  getSVG = () => this.#getTemplate().then(e => e.template)
  getBase64SVG = () => this.getSVG().then(e => `data:image/svg+xml,${encodeURIComponent(e)}` as const)

  async render(type: `image/${string}` = 'image/png', scale = 3): Promise<SignatureResult> {
    const { rect, template } = await this.#getTemplate()
      .catch((cause) => {
        throw new Error('SignatureRenderer(#getTemplate): Failed to render template', { cause })
      })

    const base64 = `data:image/svg+xml,${encodeURIComponent(template)}`

    const blob = await convertImage(base64, { type, scale })
      .catch((cause) => {
        throw new Error('SignatureRenderer(convertImage): Failed to convert image from SVG', { cause })
      })

    return { width: rect.width, height: rect.height, scale, blob }
  }
}
